// import * as React from 'react';
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Image,
  NumberInput,
  Select,
  Stack,
  Stat,
  StatNumber,
  Text,
  VStack,
  NumberInputField,
  Spinner,
  SimpleGrid,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import BoundingBox from './components/BoundingBox';
import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';

type Pair = [Date, number];
type graphType = {
  purchasedBTC: number;
  when: string;
  totalBTC: number;
};

export const App = () => {
  const frequencyChoices = [
    'Daily',
    'Weekly',
    'Bi-Weekly',
    'Monthly',
    'Quarterly',
  ] as const;
  let years: string[] = [];
  for (let index = 2; index < 8; index++) {
    years.push(index + ' Years');
  }
  const durationChoices = ['3 Months', '6 Months', '1 Year', ...years] as const;
  const [dollars, setDollars] = useState(NaN);
  const [bitcoin, setBitcoin] = useState(NaN);
  const currentPrice = useRef(18000);
  const [totalDollarsSpent, setTotalDollarsSpent] = useState(NaN);
  const [frequencySelection, setFrequencySelection] = useState(
    frequencyChoices[4],
  );
  const [durationSelection, setDurationSelection] = useState(
    durationChoices[3],
  );
  const [graphData, setGraphData] = useState<Array<graphType>>();
  // helper declaration to get typing right
  const now = new Date();
  const p: Pair = [now, 0];
  const pricePairs = useRef([p]);
  const purchasedPairs = useRef([p]);

  const graphFmtDate = (d: Date) => {
    let parts = d.toDateString().split(' ');
    return parts[1] + '`' + parts[3].slice(2);
  };

  const getStartDate = (): Date => {
    //initialize to today, throwing out time since midnight
    let d = new Date();
    d.setHours(0, 0, 0, 0);
    //2 local helper functions
    const setToXyearsAgo = (x: number): Date => {
      const currentYear = d.getFullYear();
      d.setFullYear(currentYear - x);
      return d;
    };
    const setToXmonthsAgo = (x: number): Date => {
      const currentMonth = d.getMonth();
      if (currentMonth < x) {
        //0 based indexing
        d.setMonth(11 - (x - currentMonth));
        return setToXyearsAgo(1);
      } else {
        d.setMonth(currentMonth - x);
        return d;
      }
    };
    //switch to dispatch array choices
    switch (durationSelection) {
      //@ts-ignore
      case '3 Months':
        return setToXmonthsAgo(3);
      //@ts-ignore
      case '6 Months':
        return setToXmonthsAgo(6);
      default:
        const year = parseInt(durationSelection[0]);
        if (!year) throw new Error('Invalid year selection');
        return setToXyearsAgo(year);
    }
  };

  const getBuyHistory = (): Pair[] => {
    if (!priceQuery) return [];
    const startDate = getStartDate();
    // console.log('started at:', startDate);
    const getStartIndex = () => {
      return pricePairs.current.findIndex(
        (p: Pair) => startDate.toDateString() === p[0].toDateString(),
      );
    };
    // console.log('start', startDate);
    switch (frequencySelection) {
      //@ts-ignore
      case 'Daily':
        // return a [day, price] for every day after start date
        return pricePairs.current.slice(getStartIndex());
      //@ts-ignore
      case 'Weekly':
        return pricePairs.current
          .slice(getStartIndex())
          .filter((p, index) => index % 7 === 0);
      //@ts-ignore
      case 'Bi-Weekly':
        return pricePairs.current
          .slice(getStartIndex())
          .filter((p, index) => index % 14 === 0);
      //@ts-ignore
      case 'Monthly':
        return pricePairs.current
          .slice(getStartIndex())
          .filter((p) => p[0].getDate() === 1);
      //@ts-ignore
      case 'Quarterly':
        return pricePairs.current
          .slice(getStartIndex())
          .filter((p) => p[0].getDate() === 1 && p[0].getMonth() % 3 === 0);
    }
  };

  const {
    data: priceQuery,
    error,
  } = useSWR(
    'https://btc-price-ws.herokuapp.com/historical_data/usd/max',
    (url: string) => fetch(url).then((r) => r.json()),
  );

  // when we get a new priceQuery, store the pairs in pricePairs
  useEffect(() => {
    if (!priceQuery || !priceQuery.prices) return;
    pricePairs.current = priceQuery.prices.map((p: [number, number]) => {
      let date: Date = new Date(p[0]);
      return [date, p[1]];
    });
    currentPrice.current = pricePairs.current[pricePairs.current.length - 1][1];
    // console.log('currentPrice', currentPrice.current);
    // console.log(
    //   'pricePairs',
    //   pricePairs.current.length,
    // );
  }, [priceQuery]);

  //updates based on new inputs to the form
  useEffect(() => {
    purchasedPairs.current = getBuyHistory();
    // console.log('total buys', purchasedPairs.current.length);
    if (dollars && !isNaN(dollars)) {
      setTotalDollarsSpent(dollars * purchasedPairs.current.length);
      // purchasedPairs.current.map((p) => {
      //   console.log('bought', dollars / p[1], 'btc on', p[0].toDateString());
      // });
      setBitcoin(
        purchasedPairs.current.reduce(
          (amt: number, p: Pair) => amt + dollars / p[1],
          0,
        ),
      );
      let totalbtcBought = 0;
      setGraphData(
        purchasedPairs.current.map(
          (p): graphType => {
            totalbtcBought += dollars / p[1];
            return {
              purchasedBTC: dollars / p[1],
              when: graphFmtDate(p[0]),
              totalBTC: totalbtcBought,
            };
          },
        ),
      );
    }
  }, [frequencySelection, durationSelection, dollars]);

  return (
    <>
      <Flex
        w="100%"
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="1.5rem"
        bg="black"
        color="white"
      >
        <Flex w="90%%">
          <Heading as="h1" size="lg">
            Bitcoin DCA Calculator
          </Heading>
        </Flex>
        <ColorModeSwitcher justifySelf="right" />
      </Flex>

      <BoundingBox>
        <Center>
          <VStack spacing={8} w="100%">
            <SimpleGrid
              columns={[1, 1, 2]}
              spacing={10}
              marginTop="10"
              w="100%"
            >
              <Box w="100%" pr={[4, 0]}>
                <Heading alignSelf="flex-start" textAlign="left">
                  Should I buy Bitcoin?
                </Heading>
                <Box w="100%">
                  <Box
                    w="62px"
                    h="2px"
                    bgColor="#000"
                    alignSelf="flex-start"
                    mt={0}
                    marginTop="4"
                    marginBottom="4"
                  />
                </Box>
                <Text color="#8D8D8D" fontSize="md" textAlign="left" my={4}>
                  This Bitcoin investment calculator helps you explore different
                  DCA parameters to see how your portfolio would have performed.
                  This can help you identify the best strategies for your future
                  investments in Bitcoin.
                </Text>
                <Box>
                  <Flex
                    fontSize="2xl"
                    fontWeight="800"
                    display="inline"
                    textAlign="left"
                  >
                    <Text>If I had purchased</Text>
                    <NumberInput
                      size="lg"
                      color="#FF9900"
                      variant="unstyled"
                      borderBottom="2px"
                      borderColor="#000"
                      autoFocus
                      min={0}
                      value={!isNaN(dollars) ? '$' + dollars : ''}
                      onChange={(s) => setDollars(parseInt(s))}
                    >
                      <NumberInputField
                        maxW="160px"
                        border="none"
                        focusBorderColor="none"
                        fontSize="2xl"
                        fontWeight="800"
                        placeholder="$"
                        textAlign="left"
                      />
                    </NumberInput>

                    <Text>worth of Bitcoin</Text>
                    <Box borderBottom="2px" borderColor="#000">
                      <Select
                        // pl={4}
                        size="lg"
                        color="#FF9900"
                        variant="unstyled"
                        fontSize="2xl"
                        fontWeight="800"
                        textAlign="end"
                        value={frequencySelection}
                        onChange={(e) =>
                          //@ts-ignore
                          setFrequencySelection(e.target.value)
                        }
                      >
                        {frequencyChoices.map((c) => (
                          <option key={c} value={c}>
                            {c}
                          </option>
                        ))}
                      </Select>
                    </Box>

                    <Text>for the past</Text>
                    <Box borderBottom="2px" borderColor="#000">
                      <Select
                        // pl={4}
                        size="lg"
                        color="#FF9900"
                        variant="unstyled"
                        fontSize="2xl"
                        fontWeight="800"
                        textAlign="end"
                        value={durationSelection}
                        //@ts-ignore
                        onChange={(e) => {
                          setDurationSelection(e.target.value);
                        }}
                      >
                        {durationChoices.map((c) => (
                          <option key={c} value={c}>
                            {c}
                          </option>
                        ))}
                      </Select>
                    </Box>
                  </Flex>
                </Box>
              </Box>

              <Box boxShadow="md" borderRadius="xl">
                <Center textAlign="center">
                  <Flex fontWeight="800" display="inline-block">
                    <Stat>
                      <StatNumber color="#31D037">
                        {/* +{isNaN(ROI) ? '' : ROI.toString() + '%'} */}${' '}
                        {isNaN(bitcoin)
                          ? '—'
                          : (
                              bitcoin * currentPrice.current -
                              totalDollarsSpent
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                      </StatNumber>
                    </Stat>
                    <Text fontSize="sm">Total Profit</Text>

                    <Stat>
                      <StatNumber>
                        {/* $ {isNaN(avgPurchPrice) ? '' : avgPurchPrice} */}${' '}
                        {isNaN(totalDollarsSpent / bitcoin)
                          ? '—'
                          : (totalDollarsSpent / bitcoin).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              },
                            )}
                      </StatNumber>
                    </Stat>
                    <Text fontSize="sm">Average Purchase Price</Text>

                    <Stat>
                      <StatNumber>
                        ${' '}
                        {(totalDollarsSpent && !isNaN(totalDollarsSpent)) ||
                        !isNaN(dollars)
                          ? totalDollarsSpent.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })
                          : '—'}
                      </StatNumber>
                    </Stat>
                    <Text fontSize="sm">Total Invested</Text>
                  </Flex>
                </Center>
                {error && <Text>Something went wrong</Text>}
                {!priceQuery && !error && <Spinner />}
                {priceQuery && (
                  <Flex h={[300, 500]} pr={[4, 0]}>
                    <ResponsiveContainer>
                      <LineChart
                        width={100}
                        data={graphData}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="when" />
                        <YAxis />
                        <Tooltip
                          separator=": "
                          formatter={(value, _name, _props) => {
                            //@ts-ignore
                            return value.toFixed(5);
                          }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="purchasedBTC"
                          stroke="#82ca9d"
                          activeDot={{ r: 3 }}
                          name="This purchase"
                        />
                        <Line
                          type="monotone"
                          dataKey="totalBTC"
                          stroke="#FF9900"
                          name="Total acquired"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Flex>
                )}
              </Box>
            </SimpleGrid>
          </VStack>
        </Center>
      </BoundingBox>

      {/* footer */}
      <Flex justifyContent="center" py={4} bg='black' mt={20}>
          <Stack
            pt={4}
            direction={['column', 'row']}
            justifyContent="space-between"
            spacing={[2, 8]}
            textAlign="center"
            color='white'
          >
            <ChakraLink
              href="https://bitcoinmagazine.com/"
              isExternal
              display="block"
              mb={2}
              color='white'
            >
              Bitcoin Magazine
            </ChakraLink>
            <ChakraLink
              href="https://earncarrot.com/"
              isExternal
              display="block"
              mb={2}
            >
              Carrot
            </ChakraLink>
            <ChakraLink
              href="https://b.tc/conference"
              isExternal
              display="block"
              mb={2}
            >
              Bitcoin 2021
            </ChakraLink>
            <ChakraLink
              href="https://bitcoinblackfriday.com/"
              isExternal
              display="block"
              mb={2}
            >
              Bitcoin Black Friday
            </ChakraLink>
          </Stack>
        </Flex>

        <Flex justifyContent="center" pb={6} bg='black'>
          <Text textAlign="center" color='#f2f2f2'  display='block'>
            Created with ❤️ in Nashville by{' '}
            <ChakraLink href="https://b.tc">BTC Inc</ChakraLink>
          </Text>
        </Flex>

        <Flex justifyContent="center" pb={6} bg='black'>
        <Image
                height={16}
                src="/btc-inc-logo.svg"
                alt="Bitcoin magazine logo"
                htmlHeight="200px"
                display='block'
              />
        </Flex>


    

    </>
  );
};
