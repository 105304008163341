import { Box } from '@chakra-ui/react';
import React from 'react';
import { ReactNode } from 'react';

const BoundingBox: React.FC<{ chidren?: ReactNode; pad?: boolean }> = ({
  children,
  pad,
}) => (
  <Box maxW="960px" width="100%" px={5} mx="auto" py={pad ? 20 : 0}>
    {children}
  </Box>
);

export default BoundingBox;
